<template>
  <div class="row" style="background: #1f1e1f">
    <div class="col-xl-8 col-sm-12">
      <div class="col-sm-12 col-md-8 offset-md-2 col-xl-10 offset-xl-1">
        <article class="card">
          <vue-whatsapp-widget
            phoneNumber="+524421177251"
            companyName="cabo.party (Marrissa)"
            textReply="Typically replies within 10-15 mins (9am - 10pm)"
            companyLogo="https://imgix.cosmicjs.com/4674bea0-3e73-11ec-9828-0f2f00f103e0-telegram-peer-photo-size-1-5166023334515814245-1-0-0.jpg?h=50"
          />
          <div style="margin-top: -50px">
            <div class="header text-center" style="margin-bottom: -35px">
              <br />
              <br />
              <HeadImage color="#edbc6f" :image="mainImage" title="DINNER TABLE RESERVATIONS" caption="Grab a bite and boogie down!"></HeadImage>


              <p>
                <strong>Experience the epitome of luxury dining</strong> in Los Cabos. Enjoy <strong>world-class cuisine</strong> and <strong>impeccable service</strong> at one of Mexico's
                premier Pacific destinations.
              </p>

              <div class="diego-hero">
                <p style="padding: 3%;">
                  <strong>Easily</strong> book your table, explore reviews, and get <strong>personalized assistance</strong> for an unforgettable culinary journey.
                </p>
              </div>
              <br>
              <hr style="border: none; border-top: .8px solid rgb(24, 166, 246);">
              <h4 class="tulum-party-title">Are you looking for a place to dine in Cabo San Lucas?</h4>

              <hr>
              <RsvpViaDialog :club-config="this.rsvpConfig" ></RsvpViaDialog>
              <br>
              <br>
              <p>Our Recommended Dinner Options</p>
              <section>
                <div
                  class="row"
                  style="
            margin-top: 10px;
            padding: 3px;
            width: 110%;
            border: 2px solid #1d1d1d;
            background: linear-gradient(rgb(24, 166, 246), black);
          "
                >
                  <div
                    class="col-md-8 offset-md-2 col-sm-10 offset-sm-1"
                    :key="idx"
                    style="
              margin-top: 10px;
              background: radial-gradient(#303030, black);
            "
                    v-for="(event, idx) in incomingParties.filter(x => x.slug.match(/dinner/))"
                  >
                    <DinnerCard
                      :event="event"
                      withVenue="true"
                      :key="event.title"
                    ></DinnerCard>
                  </div>
                </div>
              </section>

              <div
                class="lds-dual-ring"
                v-if="this.$store.state.status.loading"
                style="position: fixed; z-index: 9999"
              >
                LOADING
              </div>
            </div>
            <section>
              <div class="row" style="margin-top: 40px">
                <div
                  class="col-10 card centered text-center"
                  style="background-color: black; padding: 20px"
                >
                  <h4 class="section-header">NEED SOME HELP TO MAKE A DECISION?</h4>
                  <img
                    v-lazy="djs + '?w=180&h=200&fit=clamp'"
                    height="200px"
                    width="180px"
                    class="centered"
                  />
                  <br>
                  <div class="text-center centered">
                    <MiniCarrousel
                      title="Let us be your guide in Los Cabos."
                      folder="cabo-dinner-reservations"
                      num-photos="3"
                    ></MiniCarrousel>
                  </div>
                  <br />

                  <p>
                    Explore menus, layouts, and preferences for Los Cabos' finest dining.
                    <strong>Reserve your table or inquire now.</strong>
                  </p>
                  <br>

                  <div class="text-center">
                    <RsvpViaDialog :club-config="this.rsvpConfig" ></RsvpViaDialog>
                    <br />
                    <br />
                    <a href="mailto:bookings@cabo.party">bookings@cabo.party</a>
                    <br />
                    <small> We operate from 9AM - 10PM / Answer within 15 mins</small>
                    <br />
                    <br />
                    <small style="color: #fff; font-weight: bolder">NO EXTRA FEE</small>
                  </div>
                  <br>
                  <hr style="border: none; border-top: .8px solid rgb(24, 166, 246);">
                  <p>
                    Discover why Los Cabos is a top culinary destination.
                  </p>
                  <div class="diego-hero">
                    <p style="padding: 3%;">
                      Its <strong>exclusive</strong> restaurants, favored by celebrities and tastemakers, offer <strong>unparalleled dining experiences.</strong>
                    </p>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </article>
      </div>
    </div>
    <div class="card col-4 col-xl-4 d-none d-xl-inline">
      <br/>
      <h4 class="section-header" style="font-weight: bold">
        <i class="tim-icons icon-chat-33"></i> GET IN TOUCH
      </h4>
      <div class="row">
        <div class="col-sm-12 text-center">
          <p><strong>Feeling Lost?</strong></p>
          <hr/>
          <p>
            Let us be your guide. Discover the best party & events along the way.
          </p>
          <hr/>
        </div>
        <div class="col-sm-12 text-center">
          <RsvpViaDialog text="CONTACT US"></RsvpViaDialog>
          <hr/>
          <small>NO EXTRA FEE</small>
        </div>
      </div>
      <br/>


      <section
      >
        <h4 class="section-header">> RESOURCES</h4>
        <hr style="height: 2px" :style="{ backgroundColor: color }"/>
        <ul>
          <li>
            <router-link :to="{ path: '/los-cabos-mexico-event-calendar' }">Calendar</router-link>
          </li>
          <li>
            <router-link :to="{ path: '/los-cabos-clubs' }">Clubs</router-link>
          </li>
          <li>
            <router-link :to="{ path: '/los-cabos-nightlife' }">NightLife Guide</router-link>
          </li>
        </ul>
      </section>
      <section
      >
        <h4 class="section-header">> Explore</h4>
        <hr style="height: 2px" :style="{ backgroundColor: color }"/>
        <ul>
          <li>
            <router-link :to="{ path: '/los-cabos-clubs/taboo-los-cabos' }">Taboo Los Cabos</router-link>
          </li>
          <li>
            <router-link :to="{ path: '/los-cabos-shorts/fire-shows-rosa-negra-los-cabos-video-youtube'}">Rosa Negra Fire Show</router-link>
          </li>
          <li>
            <router-link :to="{ path: '/los-cabos-shorts/rufus-du-sol-sundream-festival-los-cabos-video'}">Rufus Du Sol</router-link>
          </li>
        </ul>
      </section>

      <section
      >
        <h4 class="section-header">
          > CONNECT
        </h4>
        <hr style="height: 2px" :style="{ backgroundColor: color }"/>
        <ul>
          <li>
            <a href="https://www.instagram.com/cabo.party/" target="_blank"
            >Instagram</a
            >
          </li>
        </ul>
      </section>
      <section>
        <h4 class="section-header">> Popular</h4>
        <hr style="height: 2px" :style="{ backgroundColor: color }"/>
        <DynamicLinks :links="links"></DynamicLinks>
      </section>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import  DinnerCard from '@/components/EventCard/DinnerCard';
import DynamicLinks from '@/components/DynamicLinks';
import { COSMIC_BASE_URL } from '@/common/request';
import MiniCarrousel from '@/components/MiniCarrousel';
import RsvpViaDialog from "@/components/Reservations/RsvpViaDialog";
import HeadImage from "@/components/HeadImage/HeadImage";

const VueWhatsappWidget = () =>
  import(
    /* webpackChunkName: "vue-whatsapp-widget" */ '../../components/WhatsAppWidget/Widget'
    );

export default {
  name: 'Diners',
  data: () => {
    return {
      logito:
        'https://imgix.cosmicjs.com/8c49adc0-c592-11ee-8294-5bdacc8e0a52-astro-dj.jpg',
      djs: 'https://image.lexica.art/full_webp/1b49dba4-a108-4b8f-8589-ed97ad7b1135',
      blueEyeImage:
        'https://imgix.cosmicjs.com/d81fa300-9a2a-11ee-b34c-99d1d89e42eb-eye-blue.png',
      rsvpConfig: {
        name: 'Dinner Reservation',
        logo: "https://imgix.cosmicjs.com/6f62e280-1452-11ef-9eca-7d347081a9fb-salsa-dancin.jpg"},
      mainImage:
        'https://imgix.cosmicjs.com/6f62e280-1452-11ef-9eca-7d347081a9fb-salsa-dancin.jpg',
    };
  },
  metaInfo() {
    return {
      title: `Los Cabos | Dinner Table Reservations`,
      link: [
        {
          rel: 'canonical',
          href: 'https://www.cabo.party/los-cabos-dinner-table-reservations',
        },
      ],
      meta: [
        {
          name: 'description',
          content: `Los Cabos. Dinner Table Reservations`,
        },
        {
          name: 'keywords',
          content: `Los Cabos, San Jose Cabo, Menu, tonight, drinks, Dinner, Shows, Restaurants, Food, Dinner Options`,
        },
        {
          property: 'og:title',
          content: `Los Cabos. Dinner Table Reservations`,
        },
        {
          property: 'og:url',
          href: 'https://www.cabo.party/los-cabos-dinner-table-reservations',
        },
        {
          property: 'og:description',
          content: `Los Cabos. Dinner Table Reservations.`,
        },
        {
          property: 'og:image',
          content: this.mainImage,
        },
        { property: 'og:type', content: 'article' },
      ],
    };
  },
  components: {
    HeadImage,
    RsvpViaDialog,
    MiniCarrousel,
    DynamicLinks,
    DinnerCard,
    VueWhatsappWidget,
  },
  computed: {
    incomingParties: {
      get() {
        return this.$store.state.calendar;
      },
    },
    links: {
      get() {
        return this.$store.state.links || [];
      },
    },
    photos: {
      get() {
        return this.incomingParties.map((x) => x.metadata.main_image.imgix_url);
      },
    },
    photoCaptions: {
      get() {
        return this.incomingParties.map(
          (x) => x.title + ' - ' + x.metadata.date
        );
      },
    },
  },
  mounted: function () {
    moment.updateLocale('en', {
      week: {
        dow: 1, // Monday is the first day of the week.
      },
    });

    this.$store.dispatch('getLinks', {slug: 'los-cabos-dinner-reservations'});
    this.$store.dispatch('getAllParties').then((_x) => {
      // this.getPeriod(this.$route.query.period || 'this_week', true);
    });
  },
  methods: {
    goTo: function goTo(page) {
      this.$router.push({ path: page, replace: true });
    },
    onQuickReserveClick() {
      this.$gtag.event('reservations', {
        value: 20,
        event_category: 'reservations',
        event_label: 'dinners' });
      this.$rollbar.info('Dinner Support');
    },

    isIncomingParty: function isIncomingParty(unitOfTime, x) {
      return (
        moment()
          .startOf(unitOfTime)
          .isSame(x.metadata.date + 'T' + x.metadata.start_time, unitOfTime) &&
        (x.slug.match(/cabo/) || x.metadata.magicEvent)
      );
    },

    isIncomingNext: function isIncomingNext(unitOfTime, x) {
      return (
        moment()
          .add(1, unitOfTime)
          .startOf(unitOfTime)
          .isSame(x.metadata.date + ' ' + x.metadata.start_time, unitOfTime) &&
        (x.slug.match(/cabo/) || x.metadata.magicEvent)
      );
    },

  },
};
</script>
