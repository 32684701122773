<template>
  <div class="card card-animation-on-hover text-left" style="margin: 4px;padding: 15px;background-color: rgb(45,44,44);opacity: 0.9;"
  >
    <RsvpMiniParty :club-config="config">
      <img
        v-lazy=" this.event.metadata.main_image.imgix_url + `?q=&w=${imageSize}&h=${imageSize}&fit=clamp`"
        style="float:left;margin-right: 10px;z-index: 12; box-shadow: 0px 10px 15px 0px rgb(0 0 0);border-radius: 0"
        v-bind:alt="this.event.title"
        :height="imageSize"
        :width="imageSize"
      />
      <div
        style="display: inline;vertical-align: top;color: white;position:relative ; line-height: 0.7rem"
     >
        <span style="font-weight: 700 !important;font-size: 1rem" v-if="!withoutBrand">{{ this.event.title.split("@")[0] }}</span>
        <span style="font-weight: 700 !important;font-size: 1rem" v-if="withoutBrand">{{ this.event.title.split("@")[0].split('|')[0] }}</span>

        <p style="color: silver !important;">

          {{ getDay(this.event.metadata.date + 'T' + this.event.metadata.start_time) }},
          {{ getDay3(event.metadata.date + 'T' + event.metadata.start_time) }}
          {{ getDay2(event.metadata.date + 'T' + event.metadata.start_time) }} ●

          {{
            event.metadata.start_time
          }} Hrs

        </p>
        <small v-if="this.withVenue" style="margin-top: -50px;color: silver;text-transform: uppercase">{{ venue }} </small>
        <small style="color: silver;text-transform: uppercase" v-if="this.withBrand" >  {{ brand }} </small>
      </div>
      <br v-if="event.metadata.content">
      <br v-if="event.metadata.content">
      <br v-if="event.metadata.content">
      <div v-html="event.metadata.content" v-if="event.metadata.content">
      </div>
    </RsvpMiniParty>
  </div>
</template>
<script>
import moment from 'moment';
import {venueDecoder, brandDecoder} from "@/common/Decoder";
import RsvpMiniParty from '@/components/Reservations/RsvpMiniParty';

export default {
  name: 'MiniEventCard',
  components: {RsvpMiniParty},
  props: ['event', 'past', 'withVenue', 'withoutBrand', 'large', 'withBrand'],
  filters: {
    formattedDate: function attachPrettyDate(start_time) {
      const partyDate = start_time;
      const format = 'dddd, MMMM Do YYYY';
      const formatOptions = {
        sameDay: function () {
          return moment(`${partyDate}`, 'YYYY-MM-DD HH:mm', true).isValid()
            ? `[${this.calendar()}] `
            : `[Today ~] ${format}`;
        },
        nextDay: function () {
          return moment(`${partyDate}`, 'YYYY-MM-DD HH:mm', true).isValid()
            ? `[${this.calendar()} / ] ${format}`
            : `[Tomorrow ~] ${format}`;
        },
        nextWeek: format,
        sameElse: format,
      };

      return moment(partyDate).calendar(null, formatOptions);
    },
  },
  data: () => {
    return {};
  },
  computed: {
    config: {
      get: function () {
        return {
          event: this.event,
          niceDate: this.niceDate(this.event),
        };
      },
    },
    imageSize: {
      get: function () {
        return  this.large ? '120' : '90';
      },
    },
    venue: {
      get: function () {
        return venueDecoder(this.event.title)?.title || 'cabo';
      },
    },
    brand: {
      get: function () {
        return brandDecoder(this.event.title)?.title || '';
      },
    }
  },
  methods: {
    niceDate: function niceDate(event) {
      return `${this.getDay(
        event.metadata.date + 'T' + event.metadata.start_time
      )}
      ${this.getDay3(event.metadata.date + 'T' + event.metadata.start_time)}
      ${this.getDay2(
        event.metadata.date + 'T' + event.metadata.start_time
      )} ● ${event.metadata.start_time} Hrs`;
    },
    getDay: function getYear(date) {
      return moment(date, true).format('ddd')
    },
    getDay2: function getDay2(date) {
      return moment(date, true).format('DD')
    },
    getDay3: function getDay3(date) {
      return moment(date, true).format('MMM')
    },
    getYear: function getMonth(date) {
      return moment(date, true).format('YYYY')
    },

  }
};
</script>

